<template lang="pug">
  div
    base-snackbar(
      :errors.sync="projectCodeIndexErrors"
    )
    v-data-table(
      dense
      :headers="headers"
      :loading="projectCodeIndexGetting"
      :items="projectCodeIndexList"
      :items-per-page.sync="itemsPerPage"
      :page.sync="page"
      :server-items-length="projectCodeIndexGetCount"
    )
      template(v-slot:top)
        tr
          td(colspan="3")
            v-text-field(
              label="Search"
              append-icon="mdi-magnify"
              v-model="search"
            )
      template(v-slot:item="{ item }")
        tr
          td.primary--text {{ item.project_code }}
          td {{ item.description }}
          td
            v-tooltip(bottom color="info")
              template(v-slot:activator="{ on, attrs }")
                v-icon.info--text(
                  v-bind="attrs"
                  v-on="on"
                ) mdi-information-outline
              p {{ item.notes }}
</template>
<script>
import projectCodeRepository from '@/repositories/project-code.repository'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import { requestVars } from '@/libs/api-helper.extra'

const [projectCodeIndexVars, projectCodeIndexVarNames] = requestVars({ identifier: 'project-code-index', pagination: true })

const projectCodeIndexHandler = new VueRequestHandler(null, projectCodeIndexVarNames, {}, true)

const tableVars = () => ({
  headers: [
    { text: 'Project Code', value: 'project_code' },
    { text: 'description', value: 'description' },
    { text: 'config', sortable: false },
  ],
  search: null,
  page: 1,
  itemsPerPage: 10,
})

export default {
  name: 'ProjectCodeTable',
  created () {
    this.getProjectCodes()
    this.initWebsockets()
  },
  data () {
    return {
      ...tableVars(),
      ...projectCodeIndexVars,
    }
  },
  watch: {
    search () {
      this.getProjectCodes()
    },
    page () {
      this.getProjectCodes()
    },
    itemsPerPage () {
      this.getProjectCodes()
    },
  },
  methods: {
    initWebsockets () {
      const { echo } = this.$store.state.websocket
      echo.private('database.event')
        .listen('DBStoreEvent', this.dbStoreEvent)
    },
    dbStoreEvent ({ model, data }) {
      if (!model && this.$objectEmpty(data)) return
      if (model === 'ProjectCode') {
        this.projectCodeIndexList.push(data)
      }
    },
    getProjectCodes () {
      const handler = projectCodeIndexHandler
      const repository = projectCodeRepository.index
      const params = {
        search: this.search,
        page: this.page,
        limit: this.itemsPerPage < 0 ? 1000 : this.itemsPerPage,
      }
      handler.setVue(this)
      handler.execute(repository, [params])
    },
  },
}
</script>